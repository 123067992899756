<template>
  <div class="header">
    <h1>{{titles}}</h1>
  </div>
</template>

<script>
  export default {
    props: {
      titles: {
        type: String,
        default: ''
      },
    },
    data() {
      return {};
    },
    mounted() {},
    methods: {}
  }
</script>

<style lang="scss" scoped>
  .header {
    width: 1917px;
    height: 167px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -958px;
    z-index: 0;
    background: url('../assets/images/index/header-bg.png') no-repeat center;

    h1 {
      font-size: 40px;
      font-family: PangMenZhengDao;
      font-weight: bold;
      color: #FFFFFF;
      margin-top: 32px;
      text-align: center;
      text-shadow: 0 0 0.2em #fff,
        -0 -0 0.2em #065abe;
    }
  }
</style>