<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import { getStore, setStore } from "@/utils/store";
import { validatenull } from "@/utils/validate";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
     return {
       locale: zhCN,
       form: {
         username: '',
         password: '',
         code: "",
         randomStr: "blockPuzzle"
       },
     }
  },
  components: {
  },
  components: {},
  mounted() {
    // 实时刷新token
    // this.refreshToken()
  },
  methods: {
    // 实时检测刷新token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: "access_token",
          debug: true,
        });
        if (validatenull(token)) {
          this.$router.push({
            path: "/login",
          });
          return;
        }
        if (this.expires_in <= 1000 && !this.refreshLock) {
          this.refreshLock = true;
          this.$store.dispatch("RefreshToken").catch(() => {
            this.$router.push({
              path: "/login",
            });
            clearInterval(this.refreshTime);
          });
          this.refreshLock = false;
        }
        this.$store.commit('SET_EXPIRES_IN', this.expires_in - 10)
      }, 10000)
    },

  }
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 1920px;
  height: 1080px;
}

body {
  font-size: 16px;
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}

#app {
  font-family: Microsoft YaHei, 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background: rgba(0, 0, 0, 1);
}

#nav {
  padding: 30px;
  font-size: 16px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.container {
  position: relative;
  width: 1920px;
  margin: 0 auto;
  height: 1040px;
  margin: 0 auto;
  overflow: hidden;
  // padding-left: 16px;
  padding-right: 16px;
}

.card-title {
  font-size: 16px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 16px;
  a {
    text-decoration: none;
    color: inherit;
  }
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}
.container .el-pagination .el-select .el-input .el-input__inner {
  background: transparent !important;
  color: #fff;
}
.el-select-dropdown__list {
  background: #000;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #000 !important;
  opacity: 0.8;
}
.el-pager li {
  background: transparent !important;
}
.el-pagination .btn-prev {
  background: transparent !important;
}
.el-pagination button:disabled {
  background: transparent !important;
}
.el-pagination__total{
  color: #fff !important;
}
.el-pagination__jump{
  color: #fff !important;
}
</style>
