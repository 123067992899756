<template>
  <div class="footer">
    <h3>{{bottomTitles}}</h3>
  </div>
</template>

<script>
  export default {
    props: {
      bottomTitles: {
        type: String,
        default: ''
      },
    },
    data() {
      return {};
    },
    mounted() {},
    methods: {}
  }
</script>

<style lang="scss" scoped>
  .footer {
    width: 1075px;
    height: 59px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -537px;
    z-index: 0;
    background: url('../assets/images/index/footer-bg.png') no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 30px;
      font-family: PangMenZhengDao;
      font-weight: 400;
      color: #FFFFFF;
      text-shadow: 0 0 0.2em #fff,
        -0 -0 0.2em #065abe;
    }
  }
</style>