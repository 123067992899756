<template>
  <div class="login-main">
    <div class="title" />
    <div class="login">
      <div class="login-title">账号登录</div>
      <el-form label-width="0" :model="form" ref="form" :rules="loginRules">
        <el-form-item label="" prop="username">
          <el-input placeholder="请输入用户名" v-model="form.username"></el-input>
          <img src="../assets/images/login/user.png" />
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input placeholder="请输入密码" type="password" v-model="form.password"></el-input>
          <img src="../assets/images/login/password.png" />
        </el-form-item>
      </el-form>
      <div class="remember" @click="changeChecked" >
        <div class="check cursor"></div>
        <img v-if="checked" src="../assets/images/login/gou.png" />
        记住密码1
      </div>
      <div class="button cursor" @click="handleLogin">登录</div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import request from 'request'
import {getStore, setStore} from '@/utils/store'
import { encryption } from "@/utils/encryption";
export default {
  data() {
    return {
      checked: false,
      form: {
        username: '',
        password: '',
        code: "",
        randomStr: "blockPuzzle"
      },
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度最少为6位", trigger: "blur" }
        ]
      },
    }
  },
  mounted() {
    const password = getStore({ name: 'password' })
    const username = getStore({ name: 'username' })
    if (username && password) {
      this.checked = true
    }
    this.form = {
      username: username,
      password: password,
    }
  },
  methods: {
    changeChecked() {
       this.checked = !this.checked
    },
    async handleLogin() {
      this.$refs.form.validate(valid => {
        if (valid) {
           const form = encryption({
            data: this.form,
            key: "pigxpigxpigxpigx",
            param: ["password"],
          });
          this.form.password=form.password;
          this.$store
            .dispatch("LoginByUsername", this.form)
            .then(async res => {
              if (this.checked) {
                setStore({
                  name: 'password',
                  content: this.form.password,
                })
                setStore({
                  name: 'username',
                  content: this.form.username,
                })
              } else {
                setStore({
                  name: 'password',
                  content: '',
                })
                setStore({
                  name: 'username',
                  content: '',
                })
              }
              this.$router.push({ path: '/' });
            });
        }
      });
    }
  }
}

</script>

<style lang="scss">
.login-main {
  background: url("../assets/images/login/bg.png") no-repeat;
  background-size: contain;
  width: 1920px;
  height: 1080px;
  position: relative;
  .title {
    background: url("../assets/images/login/title.png") no-repeat;
    background-size: contain;
    width: 737px;
    height: 55px;
    position: absolute;
    left: 282px;
    top: 519px;
  }
  .login {
    width: 480px;
    height: 470px;
    background: rgba(15, 70, 140, 0.5);
    border-radius: 3px;
    position: absolute;
    right: 160px;
    top: 305px;
    box-shadow: 0px 0px 0px 8px rgba(15,70,140,0.5);
    padding: 50px;
    .remember {
      display: flex;
      align-items: center;
      margin: 30px 0;
      margin-left: 11px;
      position: relative;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      img {
        width: 21px;
        height: 18px;
        position: absolute;
      }
      .check {
        width: 21px;
        height: 21px;
        margin-right: 10px;
        border-radius: 4px;
        border: 2px solid rgba(255, 255, 255, 0.2);
      }
    }
    .button {
      width: 360px;
      height: 60px;
      line-height: 60px;
      background: #008CF5;
      border-radius: 4px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      margin: 0 auto;
    }
    .login-title {
      text-align: left;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
    }
    .el-form {
      margin-top: 10px;
      .el-form-item {
        margin-top: 31px;
        position: relative;
        img {
          position: absolute;
          left: 31px;
          top: 22px;
          width: 16px;
          height: 16px;
        }
      }
      .el-form-item__content {
        .el-input {
          width: 360px;
          height: 60px;
          border-radius: 4px;
          background: transparent;
          border: 2px solid rgba(255, 255, 255, 0.2);
          input {
            width: 360px;
            background: transparent;
            height: 60px;
            border: none;
            font-size: 16px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
            line-height: 60px;
            padding-left: 45px;
          }
        }
      }
    }
  }
}

</style>
