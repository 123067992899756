import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './router/axios'
import ECharts from 'vue-echarts'
import * as echarts from 'echarts'
import ElementUI from 'element-ui';
import VueAwesomeProgress from 'vue-awesome-progress';
import 'element-ui/lib/theme-chalk/index.css';
import { ConfigProvider } from 'ant-design-vue'

Vue.use(ConfigProvider)

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
Vue.prototype.$api = axios;

import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.use(ElementUI);
Vue.use(VueAwesomeProgress)

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
// import 'videojs-contrib-hls/dist/videojs-contrib-hls'
import 'videojs-contrib-hls.js/src/videojs.hlsjs'

Vue.prototype.$echarts = echarts
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/axis'
import 'echarts/lib/component/axisPointer'

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)



import {
  Table,
  Pagination
} from 'ant-design-vue';

Vue.use(Table)
Vue.use(Pagination)


Vue.use(VueVideoPlayer)
Vue.component('v-chart', ECharts)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
