<template>
  <div class="time">
    {{ date | formatTime }}
  </div>
</template>

<script>
  import moment from "moment";
  export default {
    data() {
      return {
        date: new Date(),
      };
    },
    filters: {
      formatTime(dataStr) {
        // 过滤时间格式
        var pattern = "YYYY-MM-DD HH:mm:ss";
        return moment(dataStr).format(pattern);
      },
    },
    mounted() {
      let _this = this;
      this.timer = setInterval(() => {
        _this.date = new Date();
      }, 1000);
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    methods: {}
  }
</script>
